const pad = (num) => `0${num}`.substr(-2);

const date = new Date();

export const updateTimeDST = (time) => {
  if (!time) return time;
  const [hours, minutes] = time.split(":");
  const DST = date.getTimezoneOffset();
  // const DSTHours = Math.floor(DST / 60) - 1;
  const DSTHours = Math.floor(DST / 60);
  date.setHours(+hours - DSTHours);
  date.setMinutes(minutes);
  return `${pad(date.getHours())}:${minutes}`;
};
